<template>
  <div class="container-fluid pt-4">
    <router-link
      :to="{
        name: $route.query.sub_url,
        query: { parents_url: $route.query.parents_url }
      }"
      v-slot="{ href, route, navigate }"
      custom
    >
      <a :href="href" @click="navigate" class="mr-2"
        ><i class="fa fa-chevron-left mr-1"></i>Back</a
      >
    </router-link>
    <div class="d-flex flex-row justify-content-between pt-2">
      <h6>Visit Access # {{ this.visit_data.visit_code }}</h6>
      <h6>
        Visitor
        <b-badge variant="light"
          ><strong v-for="visitor in this.visitors" :key="visitor.id">
            {{ visitor.name }}
          </strong></b-badge
        >
      </h6>
    </div>
    <div class="d-flex flex-row justify-content-start pt-2">
      <b-button
        variant="primary"
        @click="showAddAccessForm"
        class="w-auto mr-1"
        style="font-size: var(--font-size-normal)"
      >
        <i class="fas fa-plus"></i>
        Add Access
      </b-button>
    </div>
    <div class="d-flex flex-row justify-content-between pt-2">
      <b-table
        :busy="busy"
        :items="getAccessMetadata"
        :fields="visitAccessTableFields"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="searchTerms"
        stacked="md"
        responsive
        small
        show-empty
        striped
        fixed
      >
        <template #table-busy>
          <div class="text-center text-white my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(access_card_no)="row">
          {{ row.item.access_card_no }}
        </template>

        <template #cell(physical_card)="row">
          <i
            v-if="row.item.access_types.includes('card')"
            class="fas fa-check"
          ></i>
          <i v-else class="fas fa-times"></i>
        </template>
        <template #cell(static_qr)="row">
          <i
            v-if="
              row.item.access_types.includes('qr') &&
              row.item.qr_code_type === 'static'
            "
            class="fas fa-check"
          ></i>
          <i v-else class="fas fa-times"></i>
        </template>
        <template #cell(dynamic_qr)="row">
          <i
            v-if="
              row.item.access_types.includes('qr') &&
              row.item.qr_code_type === 'dynamic'
            "
            class="fas fa-check"
          ></i>
          <i v-else class="fas fa-times"></i>
        </template>
        <template #cell(face)="row">
          <i
            v-if="row.item.access_types.includes('face')"
            class="fas fa-check"
          ></i>
          <i v-else class="fas fa-times"></i>
        </template>
        <template #cell(action)="row">
          <b-button
            variant="secondary"
            class="w-auto mr-1"
            style="font-size: var(--font-size-normal)"
            disabled
          >
            <i class="fas fa-edit"></i>
          </b-button>
          <b-button
            variant="secondary"
            @click="triggerDeleteAllAccess(row.item)"
            class="w-auto"
            style="font-size: var(--font-size-normal)"
          >
            <i class="fas fa-trash"></i>
          </b-button>
        </template>
      </b-table>
    </div>
    <!-- add access card -->
    <b-modal
      id="addAccessForm"
      :title="addAccessTitle()"
      ref="addAccessForm"
      centered
      hide-footer
      :no-close-on-backdrop="true"
    >
      <b-form @submit="onSubmitAddAccessForm">
        <b-form-group label="Access Group" v-if="form.selected_acs_url != null">
          {{ form.access_groups }}
        </b-form-group>
        <b-form-group label="Access Level" v-if="form.selected_acs_url != null">
          {{ form.access_level }}
        </b-form-group>

        <b-form-group label="Access Type">
          <b-badge
            v-if="
              getPreference.additional_metadata
                .vms_credential_operator_physical_card
            "
            ><strong style="font-size: var(--font-size-normal)"
              >Card</strong
            ></b-badge
          >
          <b-badge
            v-if="
              getPreference.additional_metadata
                .vms_credential_operator_dynamic_qr
            "
            ><strong style="font-size: var(--font-size-normal)"
              >QR (Dynamic)</strong
            ></b-badge
          >
          <b-badge
            v-if="
              getPreference.additional_metadata
                .vms_credential_operator_static_qr
            "
            ><strong style="font-size: var(--font-size-normal)"
              >QR (Static)</strong
            ></b-badge
          >
          <b-badge
            v-if="
              getPreference.additional_metadata
                .vms_credential_operator_face_recognition
            "
            ><strong style="font-size: var(--font-size-normal)"
              >Face</strong
            ></b-badge
          >
        </b-form-group>

        <b-form-group
          label="Access Card No"
          v-if="
            getPreference.additional_metadata
              .vms_credential_operator_physical_card
          "
        >
          <b-input-group>
            <b-form-input v-model="form.access_card_no" required></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="text-danger" v-if="formErrorMessage">
          {{ formErrorMessage }}
        </div>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data: function () {
    return {
      visit_data: {
        badges: {},
        status: null,
        additional_metadata: {},
        id: null,
        access_metadata: {},
        registration_type: null,
        visit_type: null,
        created: null,
        modified: null,
        visit_start_date: null,
        visit_start_time: null,
        visit_end_date: null,
        visit_end_time: null,
        tolerance_time_in: null,
        tolerance_time_out: null,
        vehicles: {},
        visit_days: {},
        visit_purpose: null,
        visit_extensions: {}
      },
      allMessaging: {},
      acsUrls: [],

      hosts: [],
      visitors: [],
      company: {},
      settings: {},

      all_access_groups: {},
      all_access_levels: {},

      formList: {
        access_card_no: null,
        physical_card: false,
        static_qr: false,
        dynamic_qr: false,
        face: false
      },

      form: {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null
      },

      formErrorMessage: null,

      busy: false,
      visitAccessList: [],
      visitAccessTableFields: [
        {
          key: "access_card_no",
          label: "Card No.",
          sortDirection: "desc"
        },
        {
          key: "physical_card",
          label: "Physical Card",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "static_qr",
          label: "Static QR",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "dynamic_qr",
          label: "Dynamic QR",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "face",
          label: "Face Access",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
          sortDirection: "desc"
        }
      ],
      sortBy: "created",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      searchTerms: "",
      access: null
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreference"];
    },
    getSettings() {
      return this.$store.getters["vmsSettingsModule/getSettings"];
    },
    getMasterHost() {
      let output = {
        id: "",
        contact_number: "",
        profile: {},
        is_active: true,
        created: "",
        name: "",
        company_id: "",
        email: "",
        additional_metadata: {},
        modified: ""
      };

      if (this.hosts.length > 0) {
        return {
          ...output,
          ...this.hosts[0]
        };
      } else {
        return {
          ...output
        };
      }
    },
    getMasterVisitor() {
      let output = {
        email: "",
        id: "",
        passport_number: "",
        profile: {},
        is_blocklisted: false,
        created: "",
        name: "",
        nric_number: "",
        contact_number: "",
        face_image: "",
        additional_metadata: {},
        modified: ""
      };

      if (this.visitors.length > 0) {
        return {
          ...output,
          ...this.visitors[0]
        };
      } else {
        return {
          ...output
        };
      }
    },
    getAccessMetadata() {
      let access = [];
      if (
        this.visit_data.access_metadata.visitors !== undefined &&
        this.getMasterVisitor.id !== undefined
      ) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.visit_data.access_metadata.visitors,
            this.getMasterVisitor.id
          )
        ) {
          access =
            this.visit_data.access_metadata.visitors[this.getMasterVisitor.id];
        }
      }
      return access;
    },
    getMasterAccessMetadata() {
      if (this.getAccessMetadata.length > 0) {
        return this.getAccessMetadata[0];
      } else {
        return null;
      }
    }
  },
  watch: {
    $route: function () {
      this.visit_id = this.$route.params.id ? this.$route.params.id : undefined;

      if (this.visit_id) {
        this.initial();
      }
    }
  },
  mounted() {
    this.visit_id = this.$route.params.id ? this.$route.params.id : undefined;

    if (this.visit_id) {
      this.initial();
    }
  },
  methods: {
    /** start init & get required data **/
    async initial() {
      ////
      let visit_data = await this.fetchVisit(this.visit_id);
      this.visit_data = visit_data;
      console.log("visit_data", visit_data);

      let visitors = await this.fetchVisitors(visit_data);
      console.log("visitors", visitors);
      this.visitors = visitors;

      let hosts = await this.fetchHosts(visit_data);
      console.log("hosts", hosts);
      this.hosts = hosts;

      for (let i = 0; i < this.hosts.length; i++) {
        let company = await this.fetchCompanys(hosts[i].company_id);
        console.log("company", company);
        this.company = company;
      }

      let p1Urls = await this.fetchP1Members();
      console.log("p1Urls", p1Urls);
      this.acsUrls = p1Urls;

      for (let i = 0; i < this.acsUrls.length; i++) {
        this.$set(
          this.all_access_groups,
          this.acsUrls[i],
          await this.fetchAccessGroup(this.acsUrls[i])
        );
        this.$set(
          this.all_access_levels,
          this.acsUrls[i],
          await this.fetchAccessLevel(this.acsUrls[i])
        );
      }

      this.form.selected_acs_url = this.acsUrls[0];

      console.log("this.all_access_groups", this.all_access_groups);
      console.log("this.all_access_levels", this.all_access_levels);
    },
    async fetchVisit(id) {
      var API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/?visit_id=" + id;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl];
      }
      return {};
    },
    async fetchVisitors(visit_data) {
      var API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visit_data.id +
        "/visitors/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined && Array.isArray(result[key])) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined && Array.isArray(result[validUrl])) {
        return result[validUrl];
      }
      return [];
    },
    async fetchHosts(visit_data) {
      var API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visit_data.id +
        "/hosts/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined && Array.isArray(result[key])) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined && Array.isArray(result[validUrl])) {
        return result[validUrl];
      }
      return [];
    },
    async fetchCompanys(company_id) {
      var API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/company/?company_id=" +
        company_id;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl];
      }
      return [];
    },
    async fetchP1Members() {
      var API_URL =
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl].result;
      }
      return [];
    },
    async fetchAccessGroup(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-groups/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },
    async fetchAccessLevel(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-levels/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },

    addAccessTitle() {
      return "Add Access";
    },

    accessQrContentFormatter(qr_string) {
      let myArray = qr_string.split(",");
      return myArray[1];
    },

    showAddAccessForm() {
      this.form.access_groups =
        this.all_access_groups[this.form.selected_acs_url][0]["access_group"];
      this.form.access_level =
        this.all_access_levels[this.form.selected_acs_url][1]["access_level"];

      this.$refs["addAccessForm"].show();
    },
    showEmptyFaceModal() {
      let that = this;

      this.$bvModal
        .msgBoxConfirm(
          `Visitor ${this.getMasterVisitor.name} do not have face image. Do you want to add a face image now?`,
          {
            title: "Face image required",
            size: "sm",
            buttonSize: "sm",
            okTitle: "Yes",
            cancelTitle: "No",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            console.log(value);
            that.$refs["formAddVisitor"].show();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmitAddAccessForm: function (e) {
      e.preventDefault();
      let $this = this;

      this.form.access_types =
        this.$store.getters["vmsPreferences2Module/operatorPresetCredential"];

      if (this.form.access_types.includes("face")) {
        if (this.getMasterVisitor.face_image === null) {
          console.log("empty face");
          this.showEmptyFaceModal();
          return;
        }
      }

      var API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/access/";
      const client = $this.$root.getAjaxFetchClient();

      let qr_code_type = "static";

      if (
        this.getPreference.additional_metadata
          .vms_credential_operator_dynamic_qr
      ) {
        qr_code_type = "dynamic";
      }

      let data = {
        visit_id: this.visit_id,
        visitor_id: this.getMasterVisitor.id,
        access_card_no: this.form.access_card_no,
        access_groups: [this.form.access_groups],
        access_level: this.form.access_level,
        lift_access_level: 0,
        face_image: this.getMasterVisitor.face_image,
        car_plate_no: null,
        as_format: "base64",
        qr_code_type: qr_code_type,
        access_types: this.form.access_types
      };

      console.log("POST access data");
      console.log(data);

      client
        .postRequest(API_URL, data)
        .then(async (data) => {
          console.log("data");
          console.log(data);
          if (data.detail != undefined) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });

            $this.formErrorMessage = data.detail;
          } else {
            let result = data.result;
            let validUrl = "";
            if (result !== undefined) {
              outer_loop: for (var key in result) {
                if (result[key] !== undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }

            if (result[validUrl]) {
              console.log("true");
              console.log(result[validUrl]);

              if (
                $this.getPreference.additional_metadata
                  .vms_check_in_as_soon_as_credential_is_issued
              ) {
                await this.checkIn(this.visit_data);
              }

              await this.handleAddAccessSuccess();
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    checkIn(visit_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/check-in/?visit_id=" +
        visit_data.id;

      let metadata = visit_data.additional_metadata;
      metadata = {
        ...metadata,
        check_in_time: moment().unix()
      };
      let visit_data2 = {
        ...visit_data,
        additional_metadata: metadata
      };

      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then(() => {})
        .catch(() => {});
    },
    async handleAddAccessSuccess() {
      try {
        let that = this;
        let visit_data = await this.fetchVisit(this.visit_id);
        this.visit_data = visit_data;
        that.access =
          this.visit_data.access_metadata.visitors[this.getMasterVisitor.id];
        console.log("visit_data", visit_data);

        let message = `Success add access to visitor`;

        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: message,
          message_type: "success"
        });

        this.$refs.addAccessForm.hide();
        this.resetAddAccessForm();
      } catch (e) {
        console.log("e");
        console.log(e);
      }
    },
    resetAddAccessForm() {
      this.form = {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null
      };

      if (this.acsUrls.length > 0) {
        this.form.selected_acs_url = this.acsUrls[0];
      }
    },

    /** start update access**/
    updateAccess(access) {
      this.$bvModal
        .msgBoxConfirm(
          `Confirm add QR access to visitor ${this.getMasterVisitor.name}?`,
          {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            console.log("access");
            console.log(access);

            var API_URL =
              this.getAPIServerURL + "/api/visitorservice/visit/access/";
            const client = this.$root.getAjaxFetchClient();

            let data = {
              acs_url: "https://192.168.88.229:6060",
              visit_id: this.visit_id,
              visitor_id: this.getMasterVisitor.id,
              access_card_no: access.access_card_no,
              access_groups: access.access_groups,
              access_level: 1,
              access_types: access.access_types, //["card", "lift_card", "face"],
              lift_access_level: 1,
              visit_end_date: this.visit_data.visit_end_date,
              visit_end_time: this.visit_data.visit_end_time,
              previous_data: { access }
            };

            console.log("update access data");
            console.log(data);

            client
              .putRequest(API_URL, data)
              .then(async (data) => {
                console.log("data");
                console.log(data);
                if (data.detail != undefined) {
                  await this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: data.detail,
                    message_type: "danger"
                  });

                  this.formErrorMessage = data.detail;
                } else {
                  let result = data.result;
                  let validUrl = "";
                  if (result !== undefined) {
                    outer_loop: for (var key in result) {
                      if (result[key] !== undefined) {
                        validUrl = key;
                        break outer_loop;
                      }
                    }
                  }

                  if (result[validUrl]) {
                    console.log("true");
                    console.log(result[validUrl]);

                    let visit_data = await this.fetchVisit(this.visit_id);
                    this.visit_data = visit_data;
                    console.log("visit_data", visit_data);

                    let message = `Success add access to visitor`;

                    await this.$store.dispatch(
                      "session/addGlobalAlertMessage",
                      {
                        message_text: message,
                        message_type: "success"
                      }
                    );
                  }
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    /** end update access**/

    /** start delete card or cards**/
    triggerDeleteAllAccess() {
      let that = this;
      this.$bvModal
        .msgBoxConfirm(
          `Confirm revoke all access from visitor ${this.getMasterVisitor.name}?`,
          {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            that.handleDeleteAllAccess(
              this.getMasterVisitor,
              this.getAccessMetadata[0]
            );
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async handleDeleteAllAccess(user, access) {
      let data = {
        acs_url: "https://192.168.88.229:6060",
        visit_id: this.visit_id,
        visitor_id: user.id,
        access_card_no: access.access_card_no,
        previous_data: { access }
      };

      var API_URL = this.getAPIServerURL + "/api/visitorservice/visit/access/";
      //this.getAPIServerURL + "/api/visitorservice/visit/access/all/";
      const client = this.$root.getAjaxFetchClient();

      let res = await client.deleteRequest(API_URL, data);

      console.log(res);

      if (res.detail !== undefined) {
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: res.detail,
          message_type: "danger"
        });
      } else {
        let result = res.result;
        let validUrl = "";
        if (result !== undefined) {
          outer_loop: for (var key in result) {
            if (result[key] !== undefined) {
              validUrl = key;
              break outer_loop;
            }
          }
        }

        if (result[validUrl]) {
          console.log("true");
          console.log(result[validUrl]);

          let visit_data = await this.fetchVisit(this.visit_id);
          this.visit_data = visit_data;

          await this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: `Success revoke all access from visitor ${user.name}`,
            message_type: "success"
          });
        }
      }
    }
    /** end delete card or cards**/
  }
};
</script>
